import React from 'react';
import * as AppContext from '../AppContext';
import * as AppActions from '../AppActions';
import styled from 'styled-components';
import {Button, message} from 'antd';

function DashboardSiteUpdateButton(props) {
  const {style} = props;
  const app = React.useContext(AppContext.Context);

  const _triggerSiteUpdateHook = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      await AppActions.githubRebuild();
      message.success('已觸發網站更新中...');
    } catch (err) {
      console.log('err', err);
      message.error('無法觸發網站更新！請聯絡工程團隊');
    }
    AppActions.setLoading(false);
  });

  return (
    <Button style={style} onClick={_triggerSiteUpdateHook}>
      發佈網站
    </Button>
  );
}

export default DashboardSiteUpdateButton;
