import React from 'react';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import JStorageForm from './JStorageForm';
import styled from 'styled-components';
import {message} from 'antd';
import FileUploadPanelBtn from '../../components/FileUploadPanelBtn';
import DashboardSiteUpdateButton from '../../components/DashboardSiteUpdateButton';
import ImagePreviewer from '../../components/ImagePreivewer';

const uiSchema = {
  banners: {
    items: {
      url: {
        'ui:widget': ImagePreviewer,
      },
      link: {
        'ui:placeholder': '注意*僅支援站內頁面，例如：/about',
      },
    },
  },
  custom_section: {
    title: {
      'ui:widget': 'textarea',
    },
    content: {
      'ui:widget': 'textarea',
    },
    button_link: {
      'ui:placeholder': '注意*僅支援站內頁面，例如：/about',
    },
  },
  store_location_link: {
    'ui:placeholder': 'https://....',
  },
  foretaste_order_closed_hint: {
    'ui:widget': 'textarea',
  },
  cart_hint: {
    'ui:widget': 'textarea',
  },
  cart_hint_image: {
    'ui:widget': ImagePreviewer,
  },
  siteAnnouncementImageUrl: {
    'ui:widget': ImagePreviewer,
  },
  news_banner: {
    'ui:widget': ImagePreviewer,
    'ui:help': '建議上圖尺寸為 1800 x 720',
  },
  foretaste_wedding_img: {
    'ui:widget': ImagePreviewer,
    'ui:help': '為避免圖片檔案過大，建議壓縮圖片至寬度 900px',
  },
  foretaste_birth_img: {
    'ui:widget': ImagePreviewer,
    'ui:help': '為避免圖片檔案過大，建議壓縮圖片至寬度 900px',
  },
};

function ConfigEcPage(props) {
  const app = React.useContext(AppContext.Context);
  const [siteConfigData, setSiteConfigData] = React.useState(null);
  const [productList, setProductList] = React.useState([]);

  const schema = {
    // FIXME: useMemo
    title: '',
    type: 'object',
    required: ['store_location_link'],
    properties: {
      banners: {
        title: '首頁-banner slides',
        type: 'array',
        default: [],
        minItems: 1,
        items: {
          type: 'object',
          required: ['url'],
          properties: {
            url: {
              type: 'string',
              title: '圖片',
              default: '',
            },
            link: {
              type: 'string',
              title: '網址',
              default: '',
            },
          },
        },
      },
      store_location_link: {
        title: '首頁-前往法朋 google map 連結',
        type: 'string',
        default: '',
      },
      custom_section: {
        title: '首頁-客製化區段',
        type: 'object',
        default: {
          title: '',
          content: '',
          button_link: '',
          button_text: '',
          image: '',
        },
        properties: {
          title: {
            type: 'string',
            title: '標題',
          },
          content: {
            type: 'string',
            title: '內文',
          },
          button_link: {
            type: 'string',
            title: '按鈕站內連結',
          },
          button_text: {
            type: 'string',
            title: '按鈕顯示文字',
          },
          image: {
            type: 'string',
            title: '圖片',
          },
        },
      },
      popular_products: {
        title: '熱門商品',
        type: 'array',
        uniqueItems: true,
        default: [],
        items: {
          type: 'string',
          title: '品名',
          default: '',
          anyOf: productList
            .filter((p) => p.published)
            .map((p) => ({
              type: 'string',
              enum: [p.id],
              title: p.name,
            })),
        },
      },

      allow_foretaste_order: {
        title: '是否開放填寫試吃表單？',
        type: 'boolean',
        default: true,
      },

      foretaste_order_closed_hint: {
        title: '試吃表單關閉文字說明',
        type: 'string',
        default: '',
      },

      show_cart_hint: {
        title: '是否於購物車頁面顯示公告資訊？',
        type: 'boolean',
        default: false,
      },

      cart_hint: {
        title: '購物車頁面公告資訊',
        type: 'string',
        default: '',
      },

      cart_hint_image: {
        title: '購物車頁面公告圖片',
        type: 'string',
        default: '',
      },

      siteAnnouncementImageUrl: {
        title: '全站公告圖片',
        type: 'string',
        default: '',
      },

      news_banner: {
        title: '最新消息橫幅',
        type: 'string',
        default: '',
      },

      foretaste_wedding_img: {
        title: '試吃喜餅圖片',
        type: 'string',
        default: '',
      },

      foretaste_birth_img: {
        title: '試吃彌月圖片',
        type: 'string',
        default: '',
      },
    },
  };

  React.useEffect(() => {
    const _fetchData = async () => {
      try {
        AppActions.setLoading(true);

        let _data = await app.actions.getHomeData();
        setSiteConfigData(_data);

        let _productList = await app.actions.getProducts();
        setProductList(_productList);
      } catch (err) {
        //
      } finally {
        AppActions.setLoading(false);
      }
    };

    _fetchData();
  }, [app.actions]);

  const _onDataUpdate = React.useCallback(
    async (formData) => {
      AppActions.setLoading(true);
      let _result = false;
      try {
        let _updated = await app.actions.staffUpdateHomeData(formData);
        setSiteConfigData(_updated);
        _result = true;
      } catch (err) {
        //
        _result = false;
      }
      AppActions.setLoading(false);

      _result
        ? message.success(
            '資料已更新，記得按下右上方「發佈網站」按鈕完成網站更新',
          )
        : message.error('資料更新失敗');
    },
    [app.actions],
  );

  if (!siteConfigData) {
    return null;
  }

  return (
    <Wrapper>
      <h2>
        前台網站設定 <DashboardSiteUpdateButton />
      </h2>

      <JStorageForm
        schema={schema}
        uiSchema={uiSchema}
        instance={siteConfigData}
        onSubmit={_onDataUpdate}
      />

      <div className="floating-actions">
        <FileUploadPanelBtn />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > h2 {
    padding: 20px 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .floating-actions {
    padding: 10px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    background-color: #fff;
    z-index: 10;
    box-shadow: 0px 0px 10px #cecece;
    flex-direction: column;
    display: flex;
  }
`;

export default ConfigEcPage;
